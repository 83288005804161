// src/components/ScriptAudioTable.js

import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import { styles } from '../styles';
import { API_BASE_URL } from '../config';
import { Play, Loader } from 'lucide-react';

const ScriptAudioTable = ({ storyId, parsedLines }) => {
  const [audioStatus, setAudioStatus] = useState({});
  const [error, setError] = useState(null);
  const [currentLine, setCurrentLine] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    checkExistingAudio();
  }, [storyId, user.user_id]);

  const checkExistingAudio = async () => {
    try {
      console.log('Checking existing audio for story:', storyId);
      const response = await fetch(
        `${API_BASE_URL}/script-audio/get-existing-audio/${storyId}?user_id=${user.user_id}`
      );

      if (!response.ok) {
        throw new Error('Failed to check existing audio');
      }

      const data = await response.json();
      if (Object.keys(data.existing_audio).length > 0) {
        console.log('Found existing audio:', data.existing_audio);
        setAudioStatus(data.existing_audio);
      }
      setHasInitialized(true);
    } catch (err) {
      console.error('Error checking existing audio:', err);
      setError('Failed to check existing audio');
      setHasInitialized(true);
    }
  };

  const startAudioGeneration = async () => {
    setIsGenerating(true);
    setError(null);
    
    // Initialize status for all lines, preserving existing audio URLs
    const initialStatus = {};
    parsedLines.forEach(line => {
      initialStatus[line.line_number] = { 
        progress: 0,
        url: audioStatus[line.line_number]?.url 
      };
    });
    setAudioStatus(initialStatus);

    try {
      // Start generation process
      const response = await fetch(`${API_BASE_URL}/script-audio/start-generation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          story_id: storyId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to start audio generation');
      }

      // Set up SSE connection for progress updates
      const eventSource = new EventSource(
        `${API_BASE_URL}/script-audio/progress/${storyId}?user_id=${user.user_id}`
      );
      
      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received SSE update:', data);

          if (data.complete) {
            console.log('Generation complete, closing event source');
            eventSource.close();
            setIsGenerating(false);
            
            // Refresh audio status after completion
            checkExistingAudio();
            return;
          }

          if (data.error) {
            console.error('Error in SSE:', data.error);
            setError(data.error);
            eventSource.close();
            setIsGenerating(false);
            return;
          }

          setAudioStatus(current => {
            console.log('Updating status for line', data.line_number, 'Progress:', data.progress);
            const newStatus = { ...current };
            const lineStatus = newStatus[data.line_number] || {};
            
            newStatus[data.line_number] = {
              ...lineStatus,
              progress: data.progress,
              url: data.url || lineStatus.url
            };
            
            // Force a re-render by creating a new object
            return { ...newStatus };
          });
        } catch (err) {
          console.error('Error processing SSE message:', err);
        }
      };

      eventSource.onerror = (err) => {
        console.error('SSE error:', err);
        eventSource.close();
        setIsGenerating(false);
        setError('Lost connection to server');
      };

    } catch (err) {
      console.error('Error starting generation:', err);
      setError(err.message);
      setIsGenerating(false);
    }
  };

  const playAudio = async (lineId) => {
    if (!audioStatus[lineId]?.url) {
      console.error('No audio URL for line', lineId);
      return;
    }

    try {
      // Stop any currently playing audio
      if (currentLine !== null) {
        setCurrentLine(null);
      }

      console.log('Playing audio for line', lineId, 'URL:', audioStatus[lineId].url);
      const audio = new Audio(audioStatus[lineId].url);
      
      // Ensure audio is loaded before playing
      await audio.load();
      
      audio.onerror = (e) => {
        console.error('Audio error:', e);
        setError(`Failed to play audio for line ${lineId}`);
        setCurrentLine(null);
      };
      
      audio.onended = () => {
        console.log('Audio playback complete for line', lineId);
        setCurrentLine(null);
      };
      
      setCurrentLine(lineId);
      await audio.play();
    } catch (err) {
      console.error('Error playing audio:', err);
      setError(`Failed to play audio for line ${lineId}`);
      setCurrentLine(null);
    }
  };

  const renderProgressOrPlayButton = (line) => {
    const status = audioStatus[line.line_number];
    
    if (!status) {
      console.log('No status for line', line.line_number);
      return null;
    }

    if (status.url) {
      console.log('Rendering play button for line', line.line_number);
      return (
        <button
          onClick={() => playAudio(line.line_number)}
          style={{
            ...styles.button,
            padding: '4px 8px',
            minWidth: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: currentLine === line.line_number ? '#666' : '#007BFF'
          }}
          disabled={currentLine === line.line_number}
        >
          <Play size={16} />
        </button>
      );
    }

    if (isGenerating) {
      console.log('Rendering progress for line', line.line_number, 'Progress:', status.progress);
      return (
        <div style={{ position: 'relative', width: '40px', height: '20px' }}>
          <div style={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
            height: '4px',
            backgroundColor: '#333',
            borderRadius: '2px',
          }}>
            <div style={{
              width: `${status.progress}%`,
              height: '100%',
              backgroundColor: '#007BFF',
              borderRadius: '2px',
              transition: 'width 0.3s ease'
            }} />
          </div>
          <span style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '0.7rem',
            color: '#fff'
          }}>
            {status.progress}%
          </span>
        </div>
      );
    }

    return null;
  };

  if (error) return <div style={styles.error}>{error}</div>;
  if (!hasInitialized) return <div>Loading audio status...</div>;

  return (
    <div style={{ marginTop: '40px' }}>
      <h2 style={styles.formTitle}>Generated Audio</h2>
      
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
      }}>
        <button
          onClick={startAudioGeneration}
          style={{
            ...styles.button,
            backgroundColor: '#007BFF',
            fontSize: '1.1rem',
            padding: '12px 24px',
            opacity: isGenerating ? 0.7 : 1,
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
          disabled={isGenerating}
        >
          {isGenerating && <Loader size={16} />}
          {isGenerating ? 'Generating Audio...' : 'Generate Script Audio'}
        </button>
      </div>
      
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.tableHeader}>Line</th>
            <th style={styles.tableHeader}>Character</th>
            <th style={styles.tableHeader}>Dialogue</th>
            <th style={styles.tableHeader}>Progress/Play</th>
          </tr>
        </thead>
        <tbody>
          {parsedLines.map((line) => (
            <tr key={line.line_number} style={{
              backgroundColor: line.character_name === "Non-speaking part" ? 'rgba(255,255,255,0.03)' : undefined
            }}>
              <td style={styles.tableCell}>{line.line_number}</td>
              <td style={{
                ...styles.tableCell,
                fontStyle: line.character_name === "Non-speaking part" ? 'italic' : 'normal',
                color: line.character_name === "Non-speaking part" ? '#888' : undefined
              }}>{line.character_name}</td>
              <td style={{
                ...styles.tableCell,
                fontStyle: line.character_name === "Non-speaking part" ? 'italic' : 'normal',
                color: line.character_name === "Non-speaking part" ? '#888' : undefined
              }}>{line.dialogue_text}</td>
              <td style={styles.tableCell}>
                {renderProgressOrPlayButton(line)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScriptAudioTable;